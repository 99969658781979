@import url(fontiran.css);

@font-face {
  font-family: 'Exodar-outline';
  src: url(../../fonts/ttf/Exodar-Outline.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'iranyekan !important';
}

#logo {
  font-family: Exodar-outline !important;
}

.styles_simpleReactWeatherCity__zyECF {
  font-size: 14px;
  display: inline;
  text-transform: none;
}

.styles_simpleReactWeatherIcon__3sqXV {
  font-size: 100%;
}

.styles_simpleReactWeatherTemp__2PnUn {
  font-size: 12px;
}

.styles_simpleReactWeather__3uy4l {
  max-width: 50px;
  min-width: 50px;
  color: white;
  top: 43%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-transform: none;
}

a:link,
a:visited {
  color: #f5a344;
  text-decoration: none !important;
}

#codeInput {
  text-align: center;
  direction: ltr !important;
}

/* Child Voice */
.player {
  height: 42px;

  background: rgba(245, 158, 57, 0.8);
  border-radius: 132px;
  padding: 4px;
  padding-right: 9px;
  position: relative;
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.player img.icon {
  width: 34px;
  height: auto;
}

.player img.bar {
  position: absolute;
  bottom: 2px;
  height: 40px;
  width: 88%;
  left: 36px;
}

.progressBarNew {
  margin: 0 10px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.progressBarNewAll {
  position: absolute;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background-color: #dcdcdc;
  width: 100%;
}

.progressBarNewDone {
  border-radius: 4px;
  border: 4px solid #fff;
  background-color: #fff;
  position: absolute;
}

.progressBarNewDone.verify {
  border-radius: 4px;
  border: 1px solid #f59e39;
  background-color: #f59e39;
  position: absolute;
  width: 100%;
}

.progressBarTime {
  width: fit-content;
  font-family: inherit;
  font-size: 12px;
  color: #666666;
  margin: 0 2px;
}